import { Component, Renderer2 } from '@angular/core';
import { RouterModule, Router, NavigationEnd } from '@angular/router';
import { Subscription, first, forkJoin } from 'rxjs';
import { environment } from '../environments/environment.prod';
import { ALERT_ERROR_NAME } from './common/alert/alert-error/alert-error-name';
import { ALERT_FLASH_NAME } from './common/alert/alert-flash/alert-flash-name';
import { AuthService } from './common/core/auth/auth.service';
import { ADMIN_CUSTOMER_UI_THEME, LAST_USED_TENANT_ID, LAST_USED_TENANT_NAME } from './common/core/auth/custom';
import { Profile } from './common/core/auth/profile';
import { PROFILE_ROLE } from './common/core/auth/profile-role';
import { ProfileService } from './common/core/auth/profile.service';
import { ADMIN_UI_CONNECT_LOGOUT } from './common/core/auth/storage-keys';
import { ConfigService } from './common/core/config/config.service';
import { DomainService } from './common/core/domain/domain.service';
import { HOSTNAME } from './common/core/domain/hostname';
import { WindowRef } from './common/core/domain/window-ref';
import { I18NService } from './common/core/i18n/i18n.service';
import { LoadingWidgetComponent } from './common/core/loading-widget/loading-widget.component';
import { NavbarComponent } from './common/navbar/navbar.component';
import { NotificationItem } from './notification-item';
import { NotificationsHub } from './notifications-hub';
import { WootricService } from './web-workers/wootric/wootric.service';
import { AlertErrorComponent } from './common/alert/alert-error/alert-error.component';
import { AlertFlashComponent } from './common/alert/alert-flash/alert-flash.component';
import { PrimeNGConfig } from 'primeng/api';
import { TOKEN_RESPONSE } from './common/core/auth/connect-storage-keys';
import { Tenant } from './common/core/auth/tenant';
import { EntityOptionsMenuDataService } from './common/entity-options-menu/entity-options-menu-data.service';
import { ServiceAccessDataService } from './common/service-access/service-access-data.service';
import { DataDogService } from './data-dog.service';


@Component({
  selector: 'adminui-layout',
  standalone: true,
  imports: [RouterModule, AlertFlashComponent, AlertErrorComponent, LoadingWidgetComponent, NavbarComponent],
  templateUrl: './layout.component.html',
})
export class LayoutComponent {

  alertFlashName = ALERT_FLASH_NAME;
  alertErrorName = ALERT_ERROR_NAME;

  hasProfile = false;
  showWelcomeDialog = false;
  notificationTitle: string = '';
  profileRole = PROFILE_ROLE;

  notificationItem!: NotificationItem | null;
  showNotification = false;

  message: { [key: string]: string } = {};

  private notificationTimeout: any;
  private webSocket!: WebSocket;
  private pingInterval!: any;

  private wellKnownConfig$ = new Subscription();
  private userInfo$ = new Subscription();
  private hasAOSetup$ = new Subscription();
  private hasSubscriberTermination$ = new Subscription();

  constructor(private authService: AuthService, private profileService: ProfileService, private renderer2: Renderer2,
    private windowRef: WindowRef, private wootricService: WootricService, private configService: ConfigService,
    private i18nService: I18NService,
    private domainService: DomainService,
    private router: Router,
    private entityOptionsMenuDataService: EntityOptionsMenuDataService,
    private serviceAccessDataService: ServiceAccessDataService,
    private primeNGConfig: PrimeNGConfig,
    private dataDogService: DataDogService
  ) { }

  ngOnInit(): void {
    this.renderer2.listen('window', 'storage', (event: StorageEvent) => {
      if (event.key && event.key === ADMIN_UI_CONNECT_LOGOUT) {
        localStorage.removeItem(ADMIN_UI_CONNECT_LOGOUT);
        this.authService.handleUnauthorized();
      }
    });

    const hostname = this.windowRef.getHostname();

    this.userInfo$ = this.authService.getUserProfile().pipe(first()).subscribe(profile => {
      this.handleLastUsedTenantIdDifferentThanTenantIdFromToken(profile, hostname);

      this.profileService.profile = profile;
      this.handleI18N();

      forkJoin({
        tenantResponse: this.authService.getTenantResponseById(hostname, profile.custom[LAST_USED_TENANT_ID]),
        def: this.authService.getTranslation(environment.defaultLocale),
        t: this.authService.getTranslation(this.profileService.profile.locale)
      }).subscribe(({ tenantResponse, def, t }) => {
        this.profileService.profile.currentTenant = tenantResponse.tenants[0];
        this.profileService.profile.roles = tenantResponse.tenants[0].roles;
        this.i18nService.messages = this.i18nService.extend(def, t);
        this.handleTheme();
        this.handleProfile(hostname);
        // if (this.domainService.isTestHostname(hostname, true) ) {
        //   this.dataDogService.init();
        // }
      });
    });
  }

  ngOnDestroy(): void {
    this.wellKnownConfig$.unsubscribe();
    this.userInfo$.unsubscribe();
    this.hasAOSetup$.unsubscribe();
    this.hasSubscriberTermination$.unsubscribe();
    if (this.webSocket) {
      this.webSocket.close();
    }
  }

  onClickNotification(event: Event): void {
    event.preventDefault();
    if (this.notificationItem !== null) {
      this.windowRef.open(this.notificationItem.url, '_blank');
    }
  }

  closeNotification() {
    this.hideNotification();
  }

  private handleProfile(hostname: string): void {

    this.profileService.profile.userDetailsUri = this.authService.getUserDetailsUri(hostname);

    if (this.profileService.profile.roles.includes(PROFILE_ROLE.buAccessAdministrator)) {
      if (this.profileService.profile.roles.includes(PROFILE_ROLE.buSubscriberViewer)) {
        this.handleProfileForBuSubscriberViewer(hostname);
      } else {
        this.handleProfileForRole(hostname);
      }
    } else if (this.profileService.profile.roles.includes(PROFILE_ROLE.buSubscriberViewer)) {
      this.handleProfileForBuSubscriberViewer(hostname);
    } else if (this.profileService.profile.roles.includes(PROFILE_ROLE.subscriberAdministrator)) {
      this.handleProfileForSubscriberAdministrator(hostname);
    } else if (this.profileService.profile.roles.includes(PROFILE_ROLE.companyAdministrator)) {
      this.handleProfileForRole(hostname);
    }

  }

  private handleProfileForSubscriberAdministrator(hostname: string): void {
    this.hasAOSetup$ = this.configService.hasAOSetup().subscribe({
      next: (response) => {
        this.handleSubscriberAdministratorProfile(response.customerIsAO, hostname);
      },
      error: (err) => {
        this.handleSubscriberAdministratorProfile(false, hostname);
      }
    });
  }

  private handleProfileForBuSubscriberViewer(hostname: string): void {
    this.hasSubscriberTermination$ = this.configService.hasSubscriberTermination().subscribe({
      next: (response) => {
        this.handleBuSubscriberViewerProfile(response.subscriberTerminationAllowed, hostname);
      },
      error: (err) => {
        this.handleBuSubscriberViewerProfile(false, hostname);
      }
    });
  }

  private handleSubscriberAdministratorProfile(isAOCustomer: boolean, hostname: string): void {
    this.profileService.isAOCustomer = isAOCustomer;
    this.profileService.isTestable = this.domainService.isTestable(hostname, this.profileService.profile.email);
    this.hasProfile = true;
    this.handleDependencies();
    this.handlePrimeNGI18N();
    this.router.navigateByUrl(this.authService.getRoutingPathFromRoles(this.profileService.profile.roles));
  }

  private handleBuSubscriberViewerProfile(subscriberTerminationAllowed: boolean, hostname: string): void {
    this.profileService.allowSubscriberTermination = subscriberTerminationAllowed;
    this.profileService.isTestable = this.domainService.isTestable(hostname, this.profileService.profile.email);
    this.hasProfile = true;
    this.handleDependencies();
    this.handlePrimeNGI18N();
    this.onRouteChange();
    this.router.navigateByUrl(this.authService.getRoutingPathFromRoles(this.profileService.profile.roles));
  }

  private handleProfileForRole(hostname: string): void {
    this.profileService.isTestable = this.domainService.isTestable(hostname, this.profileService.profile.email);
    this.hasProfile = true;
    this.handleDependencies();
    this.onRouteChange();
    this.router.navigateByUrl(this.authService.getRoutingPathFromRoles(this.profileService.profile.roles));
  }

  private onRouteChange(): void {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '' && event.url !== '/') {
          this.entityOptionsMenuDataService.resetUIParentItem();
          this.serviceAccessDataService.resetSelectedIdsForMain();
        }
      }
    });
  }

  private handleDependencies(): void {
    const msg = this.i18nService.messages['wootric'];
    const options = this.wootricService.getOptions(this.profileService.profile, msg['disclaimerText'], msg['disclaimerLink']);
    this.wootricService.init(options);
    this.initWebSocket();
  }

  private handleTheme(): void {
    let theme = this.profileService.profile.custom[ADMIN_CUSTOMER_UI_THEME];
    if (!theme) {
      theme = this.profileService.getTheme();
      this.authService.patchCustomWithTheme(theme).subscribe();
    }
    this.profileService.initTheme(theme);
    this.profileService.profile.theme = theme;
  }

  private handleI18N(): void {
    const locale = this.i18nService.getLocale(environment.supportedLocale,
      environment.defaultLocale, this.profileService.profile.locale);
    this.profileService.profile.locale = locale;
  }

  private handlePrimeNGI18N(): void {
    const msg = this.i18nService.messages['common'];
    const monthNames = this.i18nService.getMonthNames(msg);
    const monthShortNames = this.i18nService.getMonthShortNames(msg);
    const dayShortNames = this.i18nService.getDayShortNameTwoLetter(msg);

    this.primeNGConfig.setTranslation({
      dayNames: dayShortNames,
      monthNames: monthNames,
      monthNamesShort: monthShortNames
    });
  }

  private handleLastUsedTenantIdDifferentThanTenantIdFromToken(profile: Profile, hostname: string): void {
    const lastUsedTenantId = profile.custom[LAST_USED_TENANT_ID];
    if (lastUsedTenantId) {
      const str = sessionStorage.getItem(TOKEN_RESPONSE);
      if (str) {
        const tenantId = this.authService.getTenantId(hostname);
        if (tenantId && tenantId !== lastUsedTenantId) {
          this.authService.setCurrentTenantOnStorage({ tenant_id: lastUsedTenantId, organization_name: profile.custom[LAST_USED_TENANT_NAME] } as Tenant);
          const url = this.authService.getConnectLoginUrl(hostname);
          this.windowRef.setLocationHref(url);
        }
      }
    }
  }

  private initWebSocket(): void {
    const hostname = this.windowRef.getHostname();

    if (hostname !== HOSTNAME.int) {
      const access_token = this.authService.getAccessToken();
      if (access_token !== null) {
        const msg = this.i18nService.messages['notifications'];

        this.webSocket = new WebSocket(this.getConnectionUrl(hostname, access_token));
        this.webSocket.onopen = () => {
          console.log('WebSocket on open');
          this.pingInterval = setInterval(() => {
            console.log("WebSocket send ping");
            const sendMessage = JSON.stringify({ action: 'ping' });
            this.webSocket.send(sendMessage);
          }, 9 * 60000);
        };

        this.webSocket.onmessage = (event: any) => {
          this.notificationItem = JSON.parse(event.data);
          if (this.notificationItem && this.notificationItem.entityName && this.notificationItem.exportType) {
            this.notificationTitle = this.i18nService.getFormattedMessage(msg['infoYourExportIsReady'],
              [msg[this.notificationItem.exportType], this.notificationItem.entityName]);
            this.showNotification = true;
            this.notificationTimeout = setTimeout(() => {
              this.hideNotification();
            }, 30000);
          }
        };

        this.webSocket.onerror = (event: any) => {
          console.error("WebSocket error received: ", event);
          clearInterval(this.pingInterval);
        }

        this.webSocket.onclose = (event: any) => {
          console.log("WebSocket closed: ", event);
          clearInterval(this.pingInterval);
        }
      }
    }
  }

  private hideNotification(): void {
    this.showNotification = false;
    this.notificationItem = null;
    clearTimeout(this.notificationTimeout);
  }

  private getConnectionUrl(hostname: string, accessToken: string): string {
    const env = this.domainService.getEnviroment(hostname);
    const nhub = <{ [key: string]: NotificationsHub }>environment.notificationsHub;
    return `${nhub[env].url}?access-token=${accessToken}`;
  }

}


