import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ENTITY_TYPE } from '../core/const/entity-type';
import { EntityOption } from '../entity-options-menu/entity-option';
import { FilterMetadata } from 'primeng/api';
import { PARAM_MATCH_MODE } from '../core/grid/param-match-mode';
import { COMPONENT_TYPE } from './component-type';
import { LastEntityItemChecked } from '../entity-list/base-entity-item/last-entity-item-checked';
import { ENTITY_ITEM_ID_KEYS_MAP } from '../core/const/entity-item-id-keys-map';
import { DIALOG_VISIBILITY } from '../core/const/dialog-visibility';
import { DISCARD_CHANGES } from './discard-changes';
import { SAVE_OPTION } from './save-option';
import { KeyId } from './key-id';

@Injectable({
  providedIn: 'root'
})
export class ServiceAccessDataService {

  private _selectedIds: { [type: string]: { [key: string]: string[] } } = {
    main: {
      connectUserId: [],
      tenantId: [],
      userGroupInvariantKey: [],
      companyGroupInvariantKey: [],
      roleInvariantKey: [],
      roleInvariantKeyConnect: []
    },
    tab: {
      connectUserId: [],
      tenantId: [],
      userGroupInvariantKey: [],
      companyGroupInvariantKey: [],
      roleInvariantKey: [],
      roleInvariantKeyConnect: []
    }
  };

  private _lastEntityItemChecked: { [type: string]: LastEntityItemChecked } = {
    main: {
      idKey: '',
      id: ''
    },
    tab: {
      idKey: '',
      id: ''
    }
  }

  private _subjectTypeSelected: { [key: string]: string } = {
    main: ENTITY_TYPE.user,
    tab: ENTITY_TYPE.user
  }

  private _contextTypeSelected: { [key: string]: string } = {
    main: ENTITY_TYPE.company,
    tab: ENTITY_TYPE.company
  };

  private _assignmentTypeSelected: { [key: string]: string } = {
    main: ENTITY_TYPE.role,
    tab: ENTITY_TYPE.role
  };

  private _entityOptionTypeSelected: { [key: string]: string } = {
    main: ENTITY_TYPE.none,
    tab: ENTITY_TYPE.none
  };

  private _currentEntityOption: { [key: string]: EntityOption } = {};
  private _oldEntityOption: { [key: string]: EntityOption } = {};

  private _pendingChangesNumber: { [key: string]: number } = {
    main: 0,
    tab: 0
  };

  private _componentTypeSelected!: string;

  private _discardChangesDialog = new Subject<string>();
  public discardChangesDialog$: Observable<string> = this._discardChangesDialog.asObservable();

  private _discardChanges = new Subject<string>();
  public discardChanges$: Observable<string> = this._discardChanges.asObservable();

  private _subjectEntityOption = new Subject<EntityOption>();
  public subjectEntityOption$: Observable<EntityOption> = this._subjectEntityOption.asObservable();

  private _contextEntityOption = new Subject<EntityOption>();
  public contextEntityOption$: Observable<EntityOption> = this._contextEntityOption.asObservable();

  private _assignmentEntityOption = new Subject<EntityOption>();
  public assignmentEntityOption$: Observable<EntityOption> = this._assignmentEntityOption.asObservable();

  private _setFocusOn = new Subject<string>();
  public setFocusOn$: Observable<string> = this._setFocusOn.asObservable();

  private _goToRootContext = new Subject<boolean>();
  public goToRootContext$: Observable<boolean> = this._goToRootContext.asObservable();


  private _change = new Subject<{ [key: string]: FilterMetadata }>();
  public change$: Observable<{ [key: string]: FilterMetadata }> = this._change.asObservable();
  
  private _changeOnCreate = new Subject<{ [key: string]: FilterMetadata }>();
  public changeOnCreate$: Observable<{ [key: string]: FilterMetadata }> = this._changeOnCreate.asObservable();

  private _refresh = new Subject<{ [key: string]: FilterMetadata }>();
  public refresh$: Observable<{ [key: string]: FilterMetadata }> = this._refresh.asObservable();

  private _refreshOnTerminateSubscriber = new Subject<boolean>();
  public refreshOnTerminateSubscriber$: Observable<boolean> = this._refreshOnTerminateSubscriber.asObservable();

  private _resetCheckboxes = new Subject<boolean>();
  public resetCheckboxes$: Observable<boolean> = this._resetCheckboxes.asObservable();

  private _resetRoleGroupCheckboxes = new Subject<boolean>();
  public resetRoleGroupCheckboxes$: Observable<boolean> = this._resetRoleGroupCheckboxes.asObservable();

  private _save = new Subject<string>();
  public save$: Observable<string> = this._save.asObservable();


  private _limitWarningDialog = new Subject<string>();
  public limitWarningDialog$: Observable<string> = this._limitWarningDialog.asObservable();

  private _removeUserFromUserGroupDialog = new Subject<string>();
  public removeUserFromUserGroupDialog$: Observable<string> = this._removeUserFromUserGroupDialog.asObservable();
  
  private _activateServiceDialog = new Subject<string>();
  public activateServiceDialog$: Observable<string> = this._activateServiceDialog.asObservable();

  private _saveWarningDialog = new Subject<string>();
  public saveWarningDialog$: Observable<string> = this._saveWarningDialog.asObservable();

  private _disableSaveButton = new Subject<boolean>();
  public disableSaveButton$: Observable<boolean> = this._disableSaveButton.asObservable();

  private _resetLastEntityItemChecked = new Subject<LastEntityItemChecked>();
  public resetLastEntityItemChecked$: Observable<LastEntityItemChecked> = this._resetLastEntityItemChecked.asObservable();

  get componentTypeSelected(): string {
    return this._componentTypeSelected;
  }

  set componentTypeSelected(componentTypeSelected: string) {
    this._componentTypeSelected = componentTypeSelected;
  }

  setEntityOptionSelected(componentType: string, option: EntityOption): void {
    this._entityOptionTypeSelected[componentType] = option.type;
    this._currentEntityOption[componentType] = option;
    this._oldEntityOption[componentType] = option;
  }

  getEntityOptionTypeSelected(componentType: string): string {
    return this._entityOptionTypeSelected[componentType];
  }

  getCurrentEntityOption(componentType: string): EntityOption {
    return this._currentEntityOption[componentType];
  }

  getOldEntityOption(componentType: string): EntityOption {
    return this._oldEntityOption[componentType];
  }

  setPendingChangesNumber(componentType: string, num: number) {
    this._pendingChangesNumber[componentType] = num;
  }

  getPendingChangesNumber(componentType: string): number {
    return this._pendingChangesNumber[componentType];
  }

  getSubjectTypeSelected(componentType: string): string {
    return this._subjectTypeSelected[componentType];
  }

  setSubjectTypeSelected(componentType: string, entityType: string): void {
    this._subjectTypeSelected[componentType] = entityType;
  }

  getContextTypeSelected(componentType: string): string {
    return this._contextTypeSelected[componentType];
  }

  setContextTypeSelected(componentType: string, entityType: string): void {
    this._contextTypeSelected[componentType] = entityType;
  }

  getAssignmentTypeSelected(componentType: string): string {
    return this._assignmentTypeSelected[componentType];
  }

  setAssignmentTypeSelected(componentType: string, entityType: string): void {
    this._assignmentTypeSelected[componentType] = entityType;
  }

  setSubjectOption(componentType: string, option: EntityOption): void {
    this._subjectTypeSelected[componentType] = option.type;
    this._subjectEntityOption.next(option);
  }

  setContextOption(componentType: string, option: EntityOption): void {
    this._contextTypeSelected[componentType] = option.type;
    this._contextEntityOption.next(option);
  }

  setAssignmentOption(componentType: string, option: EntityOption): void {
    this._assignmentTypeSelected[componentType] = option.type;
    this._assignmentEntityOption.next(option);
  }

  getSelectedIds(componentType: string, field: string): string[] {
    return this._selectedIds[componentType][field];
  }

  showLimitWarningDialog(componentType: string): boolean {
    let has = false;
    let count = 0;
    const obj = this._selectedIds[componentType];
    const keys = Object.keys(obj);
    for (let i = 0, len = keys.length; i < len; i++) {
      if (obj[keys[i]].length > 0) {
        count += 1;
      }
    }
    if (count >= 3) {
      has = true;
    }
    return has;
  }

  clearSelectionsFor(componentType: string, type: string, idKey: string): void {
    this._entityOptionTypeSelected[componentType] = type;
    this._selectedIds[componentType][idKey] = [];
    if (idKey === ENTITY_ITEM_ID_KEYS_MAP['role']) {
      this._selectedIds[componentType]['roleInvariantKeyConnect'] = [];
    }
    const value = this.getFilters(componentType);
    this._refresh.next(value);
  }

  save(): void {
    this._save.next(SAVE_OPTION.save);
  }

  refresh(componentType: string): void {
    const entityType = this._entityOptionTypeSelected[componentType]
    switch (entityType) {
      case ENTITY_TYPE.user:
      case ENTITY_TYPE.supportUser:
        this._selectedIds[componentType]['userGroupInvariantKey'] = [];
        this._selectedIds[componentType]['connectUserId'] = [];
        this._selectedIds[componentType]['roleInvariantKey'] = [];
        this._selectedIds[componentType]['roleInvariantKeyConnect'] = [];
        break;
      case ENTITY_TYPE.userGroup: this._selectedIds[componentType]['connectUserId'] = [];
        this._selectedIds[componentType]['roleInvariantKey'] = [];
        this._selectedIds[componentType]['roleInvariantKeyConnect'] = [];
        break;
      case ENTITY_TYPE.companyGroup: {
        this._selectedIds[componentType]['tenantId'] = [];
        this._selectedIds[componentType]['roleInvariantKey'] = [];
        this._selectedIds[componentType]['roleInvariantKeyConnect'] = [];
        break;
      }
      case ENTITY_TYPE.company:
      case ENTITY_TYPE.clientCompany:
      case ENTITY_TYPE.customer:
        this._selectedIds[componentType]['tenantId'] = [];
        this._selectedIds[componentType]['companyGroupInvariantKey'] = [];
        this._selectedIds[componentType]['roleInvariantKey'] = [];
        this._selectedIds[componentType]['roleInvariantKeyConnect'] = [];
        break;
      case ENTITY_TYPE.role:
      case ENTITY_TYPE.roleGroup:
        this._selectedIds[componentType]['roleInvariantKey'] = [];
        this._selectedIds[componentType]['roleInvariantKeyConnect'] = [];
        break;
    }
    this._pendingChangesNumber[componentType] = 0;
    const value = this.getFilters(componentType);
    if (entityType === ENTITY_TYPE.companyGroup || entityType === ENTITY_TYPE.company ||
      entityType === ENTITY_TYPE.clientCompany || entityType === ENTITY_TYPE.customer) {
      this._resetRoleGroupCheckboxes.next(true);
    }
    this._refresh.next(value);
  }

  goToRootContext(): void {
    this._goToRootContext.next(true);
  }

  setFocusOn(type: string): void {
    this._setFocusOn.next(type);
  }

  disableSaveButton(value: boolean): void {
    this._disableSaveButton.next(value);
  }

  discardChanges(): void {
    this._discardChanges.next(DISCARD_CHANGES.ok);
  }

  cancelDiscardChanges(): void {
    this._discardChanges.next(DISCARD_CHANGES.cancel);
  }

  showDiscardChangesDialog(): void {
    this._discardChangesDialog.next(DIALOG_VISIBILITY.show);
  }

  hideDiscardChangesDialog(): void {
    this._discardChangesDialog.next(DIALOG_VISIBILITY.hide);
  }

  hideRemoveUserFromUserGroupDialog(): void {
    this._removeUserFromUserGroupDialog.next(DIALOG_VISIBILITY.hide);
  }

  hideAndRefreshRemoveUserFromUserGroupDialog(): void {
    this._removeUserFromUserGroupDialog.next(DIALOG_VISIBILITY.hideAndRefresh);
  }
  
  hideActivateServiceDialog(): void {
    this._activateServiceDialog.next(DIALOG_VISIBILITY.hide);
  }

  hideAndRefreshActivateServiceDialog(): void {
    this._activateServiceDialog.next(DIALOG_VISIBILITY.hideAndRefresh);
  }

  hideSaveWarningDialog(): void {
    this._saveWarningDialog.next(DIALOG_VISIBILITY.hide);
  }

  hideLimitWarningDialog(): void {
    this._limitWarningDialog.next(DIALOG_VISIBILITY.hide);
  }

  change(componentType: string): void {
    const value = this.getFilters(componentType);
    this._change.next(value);
  }

  add(componentType: string, idKey: string, id: string): void {
    this._selectedIds[componentType][idKey].push(id);
    this._lastEntityItemChecked[componentType] = { id: id, idKey: idKey };
    const value = this.getFilters(componentType);
    this._change.next(value);
  }

  addOnCreate(idKey: string, id: string): void {
    this._selectedIds[COMPONENT_TYPE.main][idKey].push(id);
    this._lastEntityItemChecked[COMPONENT_TYPE.main] = { id: id, idKey: idKey };
    const value = this.getFilters(COMPONENT_TYPE.main);
    this._changeOnCreate.next(value);
  }

  addOnCreateItems(arr: KeyId[]): void {
    for (let i = 0, len = arr.length; i < len; i++) {
      this._selectedIds[COMPONENT_TYPE.main][arr[i].idKey].push(arr[i].id);
    }
    this._lastEntityItemChecked[COMPONENT_TYPE.main] = { id: arr[arr.length - 1].id, idKey: arr[arr.length - 1].idKey };
    const value = this.getFilters(COMPONENT_TYPE.main);
    this._changeOnCreate.next(value);
  }

  addWithoutTriggerChange(componentType: string, idKey: string, id: string): void {
    this._selectedIds[componentType][idKey].push(id);
    this._lastEntityItemChecked[componentType] = { id: id, idKey: idKey };
  }

  remove(componentType: string, idKey: string, id: string): void {
    this._selectedIds[componentType][idKey] = this._selectedIds[componentType][idKey].filter(value => { return value !== id; });
    const value = this.getFilters(componentType);
    this._change.next(value);
  }

  removeWithoutTriggerChange(componentType: string, idKey: string, id: string): void {
    const arr = this._selectedIds[componentType][idKey].filter(value => { return value === id; });
    if (arr.length > 0) {
      this._selectedIds[componentType][idKey] = this._selectedIds[componentType][idKey].filter(value => { return value !== id; });
    }
  }

  resetAndChangeforMain(): void {
    this._selectedIds[COMPONENT_TYPE.main] = {
      connectUserId: [],
      tenantId: [],
      userGroupInvariantKey: [],
      companyGroupInvariantKey: [],
      roleInvariantKey: [],
      roleInvariantKeyConnect: []
    }
    this._pendingChangesNumber[COMPONENT_TYPE.main] = 0;
    this._entityOptionTypeSelected[COMPONENT_TYPE.main] = ENTITY_TYPE.none;
    const data = this.getFilters(COMPONENT_TYPE.main);
    this._change.next(data);
  }

  reset(componentType: string, idKey1?: string, id1?: string, idKey2?: string, id2?: string): void {
    this._selectedIds[componentType] = {
      connectUserId: [],
      tenantId: [],
      userGroupInvariantKey: [],
      companyGroupInvariantKey: [],
      roleInvariantKey: [],
      roleInvariantKeyConnect: []
    }
    this._pendingChangesNumber[componentType] = 0;
    this._entityOptionTypeSelected[componentType] = ENTITY_TYPE.none;
    this._resetCheckboxes.next(true);
    if (idKey1 !== undefined && id1 !== undefined) {
      if (this._selectedIds[componentType][idKey1].includes(id1) === false) {
        this._selectedIds[componentType][idKey1].push(id1);
      }
      if (idKey2 !== undefined && id2 !== undefined) {
        if (this._selectedIds[componentType][idKey2].includes(id2) === false) {
          this._selectedIds[componentType][idKey2].push(id2);
        }
      }
      const data = this.getFilters(componentType);
      this._change.next(data);
    }
  }

  resetOnTerminateSubscriber(): void {
    this.resetSelectedIdsForMain();
    this._pendingChangesNumber[COMPONENT_TYPE.main] = 0;
    this._entityOptionTypeSelected[COMPONENT_TYPE.main] = ENTITY_TYPE.none;
    this._subjectTypeSelected[COMPONENT_TYPE.main] = ENTITY_TYPE.user;
    this._contextTypeSelected[COMPONENT_TYPE.main] = ENTITY_TYPE.company;
    this._assignmentTypeSelected[COMPONENT_TYPE.main] = ENTITY_TYPE.role;
    this._refreshOnTerminateSubscriber.next(true);
  }

  resetSelectedIdsForTab(): void {
    this._selectedIds[COMPONENT_TYPE.tab] = {
      connectUserId: [],
      tenantId: [],
      userGroupInvariantKey: [],
      companyGroupInvariantKey: [],
      roleInvariantKey: [],
      roleInvariantKeyConnect: []
    }
  }

  resetSelectedIdsForMain(): void {
    this._selectedIds[COMPONENT_TYPE.main] = {
      connectUserId: [],
      tenantId: [],
      userGroupInvariantKey: [],
      companyGroupInvariantKey: [],
      roleInvariantKey: [],
      roleInvariantKeyConnect: []
    }
  }

  resetLastEntityItemChecked(componentType: string): void {
    const last = this._lastEntityItemChecked[componentType];
    const arr = this._selectedIds[componentType][last.idKey].filter(value => { return value === last.id; });
    if (arr.length > 0) {
      this._selectedIds[componentType][last.idKey] = this._selectedIds[componentType][last.idKey].filter(value => { return value !== last.id; });
      this._resetLastEntityItemChecked.next(last);
      this._lastEntityItemChecked[componentType] = { id: '', idKey: '' };
      const value = this.getFilters(componentType);
      this._change.next(value);
    }
  }

  reloadMainServiceAccess(): void {
    const value = this.getFilters(COMPONENT_TYPE.main);
    this._change.next(value);
  }


  getFilters(type: string): { [key: string]: FilterMetadata } {
    const filters: { [key: string]: FilterMetadata } = {};
    const map = this.getKeysWithValue(type);
    const keys = Object.keys(map);
    for (let i = 0, len = keys.length; i < len; i++) {
      const key: string = keys[i];
      const res = map[key].filter(value => {
        return value !== undefined;
      });
      filters[key] = { matchMode: PARAM_MATCH_MODE.in, value: `[${res.toString().replace(/,/g, '|')}]` };
    }
    return filters
  }

  private getKeysWithValue(type: string): { [key: string]: any[] } {
    let map: { [key: string]: any[] } = {};
    const keys = Object.keys(this._selectedIds[type]);
    for (let i = 0, len = keys.length; i < len; i++) {
      const arr = this._selectedIds[type][keys[i]];
      if (arr.length > 0) {
        map[keys[i]] = arr;
      }
    }
    return map;
  }

}
