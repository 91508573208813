@if (profile) {
<header #navbarDefaultHeader class="navbar navbar-default" id="nc4navTopMain">
    <div class="navbar-header">
        <div #navbarBrandDiv class="navbar-brand dropdown" [ngClass]="{'open': isAppsDropdownOpen}">
            <a #appsToggle (click)="toggleAppsDropdown($event)" class="dropdown-toggle" id="nc4navAppsTrigger" href="#"
                [title]="message['applicationsTitle']" data-toggle="dropdown" role="button" aria-expanded="false"
                [attr.aria-label]="appsAriaLabel" [attr.aria-expanded]="isAppsDropdownOpen">
                <img src="./assets/images/admin/icon-small.png" alt="Admin">
                {{appName}}<span class="caret" style="right: 2rem;"></span></a>
            <ul class="dropdown-menu" role="listbox">
                @for(item of apps; track $index){
                <li>
                    <a class="app-item" role="option" tabindex="-1" rel="noopener noreferrer" [href]="item.url"
                        [target]="item.target" [attr.aria-label]="item.name">
                        <img alt="" role="presentation" [src]="item.imageUrl" width="48" height="48">
                        <span>{{item.name}}</span>
                    </a>
                </li>
                }
            </ul>
        </div>
    </div>
    <nav class="collapse navbar-collapse" id="nc4navCollapseMain" [attr.aria-label]="message['ariaLabelMenu']"
        role="navigation">
        <ul class="nav navbar-nav nav-tabs first-level" id="nc4navFlMenu" role="menubar"
            [attr.aria-label]="message['ariaLabelMenu']">

            @if(profile.roles.includes(profileRole.buAccessAdministrator) ||
            profile.roles.includes(profileRole.buSubscriberViewer)){
            @if(profile.roles.includes(profileRole.buAccessAdministrator)){
            <li #menuItem routerLinkActive="active" role="menuitem">
                <a class="nav-item" href="#" routerLink="{{rootContextPath}}/user-access"
                    tabindex="-1">{{message['menuItemUserAccess']}}</a>
            </li>
            }
            @if (profile.roles.includes(profileRole.buSubscriberViewer)) {
            <li #menuItem routerLinkActive="active" role="menuitem">
                <a class="nav-item" href="#" routerLink="{{rootContextPath}}/subscribers/user-access"
                    tabindex="-1">{{message['menuItemSubscribers']}}</a>
            </li>
            }
            } @else if (profile.roles.includes(profileRole.subscriberAdministrator)) {
            <li #menuItem routerLinkActive="active" role="menuitem">
                <a class="nav-item" href="#" routerLink="{{rootContextPath}}/user-access"
                    tabindex="-1">{{message['menuItemUserAccess']}}</a>
            </li>
            } @else if (profile.roles.includes(profileRole.companyAdministrator)) {
            <li #menuItem routerLinkActive="active" role="menuitem">
                <a class="nav-item" href="#" routerLink="{{rootContextPath}}/user-access"
                    tabindex="-1">{{message['menuItemUserAccess']}}</a>
            </li>
            }

            <li #menuDropLi class="menudrop dropdown hidden" [ngClass]="{'open': isMenuDropOpen}">
                <a class="dropdown-toggle" id="nc4navMenuDropTrigger" data-toggle="dropdown"
                    [attr.aria-expanded]="isMenuDropOpen" href="#" (click)="toggleMenuDrop($event)" tabindex="-1">
                    <span #menuDropSpan class="hidden">{{message['menu']}}</span>
                    <i class="icon-align-justify"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-right" id="nc4navMenuDropContent">
                    <li #navbarBrandLi class="dropdown navbar-brand hidden" id="nc4navBrand"
                        [ngClass]="{'is-open': isDroppedAppsMenuOpen}">
                        <a class="dropdown-toggle dropped-apps-toggle" id="nc4navDroppedAppsToggle"
                            data-toggle="dropdown" tabindex="-1" role="button"
                            [attr.aria-expanded]="isDroppedAppsMenuOpen" href="#"
                            (click)="toggleDroppedAppsMenu($event)">
                            {{appName}}
                            <span class="caret"></span>
                        </a>
                        <ul class="dropdown-menu" role="listbox">
                            @for(item of apps; track $index){
                            <li>
                                <a class="app-item" role="option" tabindex="-1" rel="noopener noreferrer"
                                    [href]="item.url" [target]="item.target" [attr.aria-label]="item.name">
                                    <img alt="" role="presentation" [src]="item.imageUrl" width="48" height="48">
                                    <span>{{item.name}}</span>
                                </a>
                            </li>
                            }
                        </ul>
                    </li>
                    @if(profile.roles.includes(profileRole.buAccessAdministrator) ||
                    profile.roles.includes(profileRole.buSubscriberViewer)){
                    @if(profile.roles.includes(profileRole.buAccessAdministrator)){
                    <li #droppedMenuItem routerLinkActive="active" role="menuitem">
                        <a class="nav-item" href="#" routerLink="{{rootContextPath}}/user-access"
                            tabindex="-1">{{message['menuItemUserAccess']}}</a>
                    </li>
                    }
                    @if (profile.roles.includes(profileRole.buSubscriberViewer)) {
                    <li #droppedMenuItem routerLinkActive="active" role="menuitem">
                        <a class="nav-item" href="#" routerLink="{{rootContextPath}}/subscribers/user-access"
                            tabindex="-1">{{message['menuItemSubscribers']}}</a>
                    </li>
                    }
                    } @else if (profile.roles.includes(profileRole.subscriberAdministrator)) {
                    <li #droppedMenuItem routerLinkActive="active" role="menuitem">
                        <a class="nav-item" href="#" routerLink="{{rootContextPath}}/user-access"
                            tabindex="-1">{{message['menuItemUserAccess']}}</a>
                    </li>
                    } @else if (profile.roles.includes(profileRole.companyAdministrator)) {
                    <li #droppedMenuItem routerLinkActive="active" role="menuitem">
                        <a class="nav-item" href="#" routerLink="{{rootContextPath}}/user-access"
                            tabindex="-1">{{message['menuItemUserAccess']}}</a>
                    </li>
                    }
                </ul>
            </li>
        </ul>
        <ul #contextSelectorUl class="nav navbar-nav nav-tabs navbar-right first-level context-selector">
            <li class="icon">
                <a href="#" (click)="onClickHelp($event)" role="button" [attr.aria-label]="message['helpAriaLabel']"
                    [title]="message['helpTitle']">
                    <span aria-hidden="true" class="vismaicon vismaicon-help"></span>
                </a>
            </li>
            <li class="icon">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSeL252hiiMNwQfQy4-NH9sO5bIrTBCRWacdrXRPfaH5UnYT8g/viewform"
                    target="_blank" rel="noopener noreferrer" role="button"
                    [attr.aria-label]="message['feedbackAriaLabel']" [title]="message['feedbackTitle']">
                    <span aria-hidden="true" class="vismaicon vismaicon-feedback"></span>
                </a>
            </li>
            <li #userDropDownLi class="dropdown user-dropdown" [ngClass]="{'open': isContextsDropdownOpen}">
                <a #contextsToggle (click)="toggleContextsDropdown($event)" class="dropdown-toggle button-context"
                    id="nc4navContextsTrigger" href="#" [title]="message['informationTitle']" data-toggle="dropdown"
                    role="button" [attr.aria-expanded]="isContextsDropdownOpen" [attr.aria-label]="infoAriaLabel">
                    <span class="username">{{profile.name}}</span>
                    <span class="vismaicon vismaicon-menu vismaicon-user"></span><span class="caret"></span>
                    <small class="customer">{{profile.currentTenant.organization_name}}</small>
                </a>

                <ul #contextsMenu class="dropdown-menu company-selection" role="menu">
                    <li class="context-autocomplete clear" role="menuitem">
                        <p-autoComplete [suggestions]="contextGroups" [(ngModel)]="selectedTenant" [group]="true"
                            (completeMethod)="onSearchContext($event)" (onSelect)="onSelectContext($event)"
                            (onBlur)="onBlurContext()" [dropdown]="true" [placeholder]="message['searchByCustomer']"
                            dropdownIcon="vismaicon vismaicon-autocomplete"
                            [dropdownAriaLabel]="message['ariaLabelOpen']" [minLength]="3" field="organization_name"
                            [showEmptyMessage]="true" [panelStyle]="{'max-height': '34rem'}"
                            panelStyleClass="combobox-dropdown" [emptyMessage]="message['noResultsFound']"
                            class="nc-combobox-element" styleClass="nc-combobox" (onFocus)="onFocus($event)">
                            <ng-template let-group pTemplate="group">
                                @if(group.type !== 'none'){
                                <div class="group-label"><span>{{group.label}}</span></div>
                                }
                            </ng-template>
                        </p-autoComplete>
                    </li>
                    <li class="divider"></li>
                    <li class="user-details-area clear" role="menuitem">
                        <div [title]="profile.email">
                            <div class="user-img" [style]="pictureStyle"></div>
                            <div class="user-text"><span>{{profile.name}}</span><span
                                    class="text-disabled">{{profile.email}}</span></div>
                        </div>
                    </li>
                    <li class="divider"></li>
                    <li class="clear" role="menuitem"><a
                            [href]="profile.userDetailsUri">{{message['buttonMyDetails']}}</a>
                    </li>
                    <li class="divider" role="menuitem"></li>
                    <li class="clear" role="menuitem"><label class="switch switch-label light-dark-toggle">
                            <strong class="light">{{message['lightMode']}}</strong>
                            <input id="option" name="option" type="checkbox" [checked]="isDarkMode"
                                (change)="onChangeMode($event, isDarkMode)" />
                            <span class="togglemark">
                                <strong class="details"></strong>
                            </span>
                            <strong class="dark">{{message['darkMode']}}</strong>
                        </label></li>
                    <li class="divider divider-strong"></li>
                    <li class="context-footer company-selection-footer clear" role="menuitem">
                        <a routerLink="/logout" href="#" class="log-out-link vismaicon vismaicon-logout" tabindex="0"
                            id="nc4navLogoutButton" [title]="message['buttonSignOut']">{{message['buttonSignOut']}}</a>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</header>
}